import React, {useEffect, useState} from 'react'
import axios from "axios"
import styles from './DocMainCardsContainer.module.css'
import Doc from "./img/docs.png"
import DocMainCard from "./DocMainCard/DocMainCard";


const TeamMainCardsContainer = (props) => {
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        axios.get("https://api.webinfra.ru/documents")
            .then((res) => {
                setDocs(res.data.results);
            });
    }, []);

    const docsCards = docs.map((el) => {
        return (
            <DocMainCard title={el.title} document={el.document} src={Doc} key={el.id} />
        )
    })

    return (
        <div className={styles.mainWrapper}>
                     <div className={styles.wrapper} >
                {docsCards}
            </div>
        </div>
    )

}

export default TeamMainCardsContainer

