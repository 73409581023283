import React from 'react';

import styles from './Footer.module.css'
import FooterInfo from './FooterInfo/FooterInfo';
import FooterLinks from './FooterLinks/FooterLinks';
import FooterCEO from "./FooterCEO/FooterCEO";

import { YMInitializer } from 'react-yandex-metrika';

const Footer = () => {

    return (
        <footer>
            <YMInitializer accounts={[62571217]} options={{webvisor: true, clickmap:true, trackLinks:true, accurateTrackBounce:true}} />
            <div className={styles.wrapper}>
                <FooterInfo />
                {/*<FooterLinks contacts='Контакты'  partners='Партнёры' instagram='web_infra' />*/}
                <FooterLinks contacts='Контакты'  partners='Партнёры' instagram='web_infra' />
                <FooterCEO />
            </div>
        </footer>
    )
}

export default Footer
